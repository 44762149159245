.ui.modal {
  background-color: $white;
  font-size: inherit;
  border-radius: 0;
  top: 10vh;
  height: fit-content;
}

.modal__close {
  @include close-label;

  top: 23px;
  right: 20px;

  @media (min-width: $sm-min) {
    top: 25px;
    right: 30px;
  }
}

.modal {
  .header {
    padding: 0 !important;
  }
}

.modal iframe {
  width: 100%;
  height: calc(100vw * (9 / 16) - 40px);

  @media (min-width: $sm-min) {
    height: calc(100vw * (9 / 16) - 100px);
  }

  @media (min-width: $md-min) {
    height: 490px;
  }
}
.ui.dimmer.modals .scrolling.modal {
  position: relative !important;
  top: auto !important;
  left: auto;
}
