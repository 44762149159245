.follow-us__flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  @media (min-width: $sm-min) {
    margin-top: -10px;
    margin-bottom: 10px;
  }

  > a {
    @include hover-enlarge;

    text-align: center;
    margin: 30px;
    font-size: 16px;
    transition: transform $transition-time, color $transition-time;

    img {
      display: block;
      margin: 0 auto;
    }
  }
}

.follow-us__item-icon {
  height: 70px;
}
