.persona {
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  isolation: isolate;
  justify-content: center;
  h1 {
    font-weight: 700;
  }

  &.persona-single {
    h3 {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }

  @media (max-width: $md-max) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
}

.persona__banner {
  z-index: -1;
  height: fit-content;
  grid-row: 1;
  grid-column: 1;
  display: grid;
  position: relative;
  grid-auto-rows: auto;
  justify-content: center;
  grid-template-columns: repeat(8, minmax(94px, 180px));
  grid-template-areas:
    'zero . blank-1 one . . . .'
    '. two blank-3 . . . . .'
    'three blank-2 . . . . . .';

  @media (max-width: $lg-max) {
    grid-template-areas:
      'zero . blank-1 . . . . .'
      '. two blank-3 . . . . .'
      'three blank-2 one . . . . .';
  }

  @media (max-width: $sm-max) {
    grid-template-columns: repeat(4, minmax(94px, 1fr));
    grid-template-areas:
      'zero two blank-1 blank-3'
      'three blank-2 . one';
  }

  @media (max-width: $xs-max) {
    grid-template-columns: repeat(3, minmax(94px, 180px));
    grid-template-areas:
      'zero two blank-2'
      'three blank-1 one';
  }
}

.persona-banner__content-wrapper {
  grid-row: 1;
  grid-column: 1;
  height: 100%;
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(8, minmax(94px, 180px));
  grid-template-areas:
    '. . . . content content content content'
    '. . . . content content content content'
    '. . . . content content content content';

  @media (max-width: $lg-max) {
    grid-template-areas:
      '. . . content content content content content'
      '. . . content content content content content'
      '. . . content content content content content';
  }

  @media (max-width: $sm-max) {
    grid-row: 2;
    display: initial;
    position: initial;
  }
}

.persona-banner__content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 65px;
  padding-right: 60px;

  .btn {
    &:hover {
      filter: brightness(80%);
    }
  }

  > h1 {
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 16px;
    line-height: 1.25;

    @media (max-width: $md-max) {
      margin-bottom: 12px;
      font-size: 32px;
    }
  }

  > h3 {
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;

    @media (max-width: $md-max) {
      font-size: 18px;
    }
  }

  > p {
    margin-bottom: 48px;

    @media (max-width: $lg-max) {
      margin-bottom: 24px;
    }
  }

  @media (max-width: $xl-max) {
    padding-left: 60px;
  }

  @media (max-width: $lg-max) {
    padding-left: 30px;
  }

  @media (max-width: $md-max) {
    margin-top: 35px;
  }

  @media (max-width: $sm-max) {
    margin: 40px 0;
  }
  .persona-banner__button-group {
    display: grid;
    gap: 16px;
    a {
      border: 3px solid $personas;
      text-align: center;
      padding: 5px 13px;

      &:hover {
        border: 3px solid $personas--dark;
        color: $white;
      }

      &:last-of-type {
        background-color: transparent;
        color: $personas !important;
        &:hover {
          color: $personas--dark !important;
        }
      }
    }
  }
}
