.community-summary-tile {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 307px 1fr;
  width: 307px;
}

.community-summary-tile__tile {
  position: relative;
  height: 100%;

  p {
    color: $white;
    font-size: 16px;
  }

  @media (min-width: $md-min) {
    &--hoverable:hover {
      .community-summary-tile__tile-summary-content {
        transition: opacity 0.1s 0.4s;
        opacity: 1;
      }

      .tile__content {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: $sm-max) {
    margin-bottom: 0;
  }
}

.community-summary-tile__tile-summary-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  opacity: 0;
  line-height: 1.37;
}

.community-summary-tile__tile::before {
  content: '';
  display: inline-block;
  padding-bottom: 100%;
}

.community-summary-tile__summary-text {
  display: flex;
  flex-direction: column;

  @media (max-width: $sm-max) {
    align-items: center;
    text-align: center;
  }
}

.community-summary-tile__summary-text--mobile-only {
  color: $black;

  @media (min-width: $md-min) {
    display: none;
  }
}

.content__summary--display-visible {
  display: grid !important;
}

.tile__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.tile__content {
  color: $white;
  padding: 20px;
  overflow: hidden;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transition-duration: 0.4s;
  transform-origin: right bottom;

  p,
  a {
    font-size: 16px;
  }
}

.tile__header {
  h4 {
    margin: 0;
    color: $white;
    line-height: 1;
    display: inline-block;
    text-align: left;
    font-weight: 700;
  }
  .tile__header-id {
    font-size: 32px;
  }

  .tile__header-name {
    font-size: 18px;
    text-transform: capitalize;
  }
}

@keyframes widen-arrow {
  from {
    d: path(
      'M23.4207 8.53084C23.8113 8.14031 23.8113 7.50715 23.4207 7.11662L17.0568 0.752663C16.6662 0.362138 16.0331 0.362138 15.6426 0.752663C15.252 1.14319 15.252 1.77635 15.6426 2.16688L21.2994 7.82373L15.6426 13.4806C15.252 13.8711 15.252 14.5043 15.6426 14.8948C16.0331 15.2853 16.6662 15.2853 17.0568 14.8948L23.4207 8.53084ZM0.996582 8.82373H22.7136V6.82373H0.996582V8.82373Z'
    );
  }

  to {
    d: path(
      'M33.7071 8.22359C34.0976 7.83306 34.0976 7.1999 33.7071 6.80937L27.3431 0.445412C26.9526 0.0548873 26.3195 0.0548873 25.9289 0.445412C25.5384 0.835936 25.5384 1.4691 25.9289 1.85963L31.5858 7.51648L25.9289 13.1733C25.5384 13.5639 25.5384 14.197 25.9289 14.5875C26.3195 14.9781 26.9526 14.9781 27.3431 14.5875L33.7071 8.22359ZM0 8.51648H33V6.51648H0V8.51648Z'
    );
  }
}

@keyframes shorten-arrow {
  from {
    d: path(
      'M33.7071 8.22359C34.0976 7.83306 34.0976 7.1999 33.7071 6.80937L27.3431 0.445412C26.9526 0.0548873 26.3195 0.0548873 25.9289 0.445412C25.5384 0.835936 25.5384 1.4691 25.9289 1.85963L31.5858 7.51648L25.9289 13.1733C25.5384 13.5639 25.5384 14.197 25.9289 14.5875C26.3195 14.9781 26.9526 14.9781 27.3431 14.5875L33.7071 8.22359ZM0 8.51648H33V6.51648H0V8.51648Z'
    );
  }

  to {
    d: path(
      'M23.4207 8.53084C23.8113 8.14031 23.8113 7.50715 23.4207 7.11662L17.0568 0.752663C16.6662 0.362138 16.0331 0.362138 15.6426 0.752663C15.252 1.14319 15.252 1.77635 15.6426 2.16688L21.2994 7.82373L15.6426 13.4806C15.252 13.8711 15.252 14.5043 15.6426 14.8948C16.0331 15.2853 16.6662 15.2853 17.0568 14.8948L23.4207 8.53084ZM0.996582 8.82373H22.7136V6.82373H0.996582V8.82373Z'
    );
  }
}

.community-tile__text--min-height {
  @media (min-width: $sm-min) {
    min-height: 230px;
  }

  @media (min-width: $xl-min) {
    min-height: 270px;
  }

  @media (min-width: 1900px) {
    min-height: 200px;
  }
}

.community-tile__button-container {
  text-align: center;
  margin-bottom: 20px;
}

.community-tile-menu {
  margin: 0 5px;
}

.community-tile-menu__link {
  background-color: rgba($white, 0.1);
  padding: 7px 4px;
  display: flex;
  flex-flow: row nowrap;
  color: white;
  text-decoration: none;
  margin-bottom: 5px;

  @include transition(background-color);

  &:hover,
  &:focus {
    background-color: rgba($white, 0.3);
    color: white;
    text-decoration: none;
  }
}

.community-tile-menu__number {
  flex: 0 0 40px;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  padding-right: 9px;
  padding-left: 9px;
}

.community-tile-menu__name {
  flex: 1 1 auto;
  font-weight: 600;
  font-size: 14px;
  margin-top: 4px;
  line-height: 1.2;
}

.community-summary-tile__summary-text-stat-wrapper {
  margin-top: 30px;
}
