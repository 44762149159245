.demo-carousel {
  margin-bottom: 20px;
  position: relative;

  @media (min-width: $sm-min) {
    margin-bottom: 40px;
  }
}

.demo-carousel__silhouette {
  width: 350px;
  top: 40%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 10;

  @media (min-width: 860px) {
    width: auto;
    top: 45%;
    max-width: 100%;
  }
}

.demo-carousel__item {
  text-align: center;

  iframe {
    width: 290px;
    height: 180px;

    @media (min-width: 860px) {
      width: 640px;
      height: 400px;
    }
  }
}

.demo-carousel--fade-in {
  animation: $transition-time fadeIn forwards;
}

.demo-carousel--fade-out {
  animation: $transition-time fadeOut forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.demo-carousel .owl-dots {
  position: relative;
  top: 20px;

  @media (min-width: 860px) {
    top: 40px;
  }
}

.demo-carousel__text {
  margin: 60px 0 40px;

  @media (min-width: $sm-min) {
    margin: 100px 0 70px;
  }
}
