.carousel {
  .control-dots {
    position: initial;
  }

  .dot {
    border: 2px solid #00a3c7;
    border-radius: 50% !important;
    background-color: transparent !important;
    width: 15px !important;
    height: 15px !important;
    padding: 0 !important;
    display: inline-block !important;
    box-shadow: none !important;
    opacity: 1 !important;
    will-change: transform;
    transition: transform 0.25s;

    &:hover {
      transform: scale(1.1);
    }

    &.selected {
      background-color: #00a3c7 !important;
    }

    &:focus {
      outline: none;
    }

    @media (max-width: $sm-max) {
      margin: 0 15px !important;
    }
  }

  .slide {
    background: none !important;
    padding: 0 120px;

    @media (max-width: $sm-max) {
      padding: 0 20px !important;
    }
  }

  .control-arrow {
    opacity: 1 !important;
    position: initial;

    @media (max-width: $sm-max) {
      display: none !important;
    }

    &:focus {
      outline: none;
    }
  }

  .control-prev.control-arrow.control-disabled {
    @media (max-width: $sm-max) {
      display: none !important;
    }
  }

  .control-next::before,
  .control-prev::before {
    width: 17px;
    height: 42px;
    background-size: contain;
    background-position: 0;
    background-repeat: no-repeat;
    pointer-events: auto;
    border: none !important;
    backface-visibility: hidden !important;
  }

  .control-prev {
    left: 2.5% !important;
    margin-left: 60px;

    &::before {
      background-image: url(../../images/icons/line-arrow-left.svg);
    }

    &.control-disabled {
      display: block !important;

      &::before {
        background-image: url(../../images/icons/line-arrow-left-grey.svg);
      }
    }
  }

  .control-next {
    right: 2.5% !important;
    margin-right: 60px;

    &::before {
      background-image: url(../../images/icons/line-arrow-right.svg);
    }

    &.control-disabled {
      display: block;

      &::before {
        background-image: url(../../images/icons/line-arrow-right-grey.svg);
      }
    }
  }

  .control-arrow:hover {
    transform: scale(1.2);
    background: none !important;

    &.control-disabled {
      transform: none;
    }
  }
}
