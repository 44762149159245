.featured-resources__wrapper {
  margin: 115px 0;
  .featured-resources__container {
    margin: auto;
    .featured-resources__title {
      text-align: left;
      font-size: 32px;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 150%;
      margin-bottom: 34px;
      @media (max-width: $sm-max) {
        text-align: center;
        margin-bottom: 19px;
        font-size: 24px;
      }
    }

    .featured-resources__grid {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: auto;
      grid-template-rows: auto;
      gap: 37px;
      @media (max-width: $md-max) {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
      }
    }
    .featured-resources__content--container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $white;
      padding: 40px 32px 28px 32px;
    }

    .featured-resources__container--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      h5 {
        color: $grey-250;
        margin-bottom: 0;
      }
    }

    .featured-resources__container--heading {
      h3 {
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 32px;
        font-weight: 700;
      }
    }

    .featured-resources__container--image {
      max-width: 80px;
      width: 100%;
    }

    .featured-resources__container--text {
      margin-bottom: 40px;
    }

    .featured-resources__container--hyperlink {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-weight: bold;
      color: $personas;
      svg {
        transition: color 0.2s;
        color: $personas;
        transform: translateY(1px);
        overflow: hidden;
        width: 40px;
        height: 40px;
        path {
          transition: transform 0.2s ease-in;
          transform: translateX(-5px);
        }
      }
      &:hover {
        color: $personas--dark;
        svg {
          color: $personas--dark;
        }
        path {
          transform: translateX(0);
        }
      }
    }
  }
}
