.back-to-top {
  @include hover-enlarge-more;

  width: 44px;
  height: 44px;
  position: fixed;
  bottom: 60px;
  right: 60px;
  background-color: $athens-grey;
  border-radius: $border-radius-sm;
  background-image: url('../../images/icons/back-to-top.svg');
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  opacity: 0;
  z-index: 60;
  transition: opacity $transition-time, transform $transition-time;
}

.back-to-top--visible {
  @media (min-width: $sm-min) {
    opacity: 1;
  }
}
