/* Brand Colours */
$blue: #004b87;
$cyan: #00aec7;
$cyan--dark: #0f849e;
$cyan--light: #7fd1e3;
$emperor: #a5a5a5;
$emperor--dark: #505050;
$heather: #bbc4d1;
$mercury: #e6e6e6;
$mischka: #bbc4d1;
$mischka-alt: #d0d5dc;
$pale-sky: #646f80;
$teal: #00b398;

/* Greyscale */
$white: #fff;
$grey-100: #f3f5f7;
$grey-200: #e3e3e3;
$grey-250: #B3B5B9;
$grey-300: #7f8794;
$grey-400: #596475;
$black: #23211d;

/* Persona Colours */
$personas: #00acca;
$personas--dark: #00799a;
$personas--dark-blue: #0031BF;
$personas--light-blue: #00ADDC;
$personas--green: #008E7F;
$personas--maroon: #B72267;
$personas--yellow: #F2A00A;
$personas--grey: #5A5758;

/* Info Colours */
$error-red: #f91c1c;
$error-red--light: #fef5f6;

/* Gradients */
$select-border: rgba(34, 36, 38, 0.15);
$select-border--hover: rgba(34, 36, 38, 0.35);
$select-item--hover: rgba(0, 0, 0, 0.05);
$select-item--selected: rgba(0, 0, 0, 0.03);
