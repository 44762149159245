.page-row {
  padding: 40px 0;
  position: relative;

  @media (min-width: $sm-min) {
    padding: 40px 0;
  }

  iframe {
    @include video-iframe;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

/* Page row padding */
.page-row--extra-bottom-padding {
  @media (min-width: $sm-min) {
    padding-bottom: 60px;
  }
}

.page-row--extra-top-padding {
  @media (min-width: $sm-min) {
    padding-top: 60px;
  }
}

.page-row--less-padding {
  @media (min-width: $sm-min) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.page-row--min-bottom-padding {
  @media (min-width: $sm-min) {
    padding-bottom: 20px;
  }
}

/* Page row background variations */
.page-row--background-white {
  background-color: $white;
}

.page-row--background-athens-grey {
  background-color: $athens-grey;
}

.page-row--background-mischka {
  background-color: $mischka;
}

.page-row--multifaceted-grey-bg {
  background-image: url('../../images/backgrounds/multifaceted_bg_grey.svg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.page-row__title {
  margin-bottom: 20px;

  @media (min-width: $sm-min) {
    margin-bottom: 20px;
  }
}

.page-row__title--single-column-row {
  margin-bottom: 20px;

  @media (min-width: $sm-min) {
    margin-bottom: 20px;
  }
}

.page-row__buttons {
  text-align: center;
  margin-top: 20px;
}

.page-row__buttons--extra-top-padding {
  margin-top: 40px;
}

.page-row.single-column + .page-row.single-column {
  padding-top: 0;
}

.page-row.single-column {
  h3 {
    margin-bottom: 20px;
    margin-top: 40px;
  }
}
