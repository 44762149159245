.get-started-with-product-cta {
  padding: 0 70px;

  @media (max-width: $sm-max) {
    padding: 0 20px;
  }
}

.get-started-with-product-cta__wrapper {
  background-color: $white;
  border-bottom: 6px solid $grey-100;

  @media (max-width: $md-max) {
    margin-top: 93px;
  }

  .get-started-with-product-cta__rainbow-stripe {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 8px;
    div {
      height: 100%;
      width: 100%;
    }
    .rainbow-stripe__dark-blue {
      background-color: $personas--light-blue;
    }
    .rainbow-stripe__blue {
      background-color: $personas--dark-blue;
    }
    .rainbow-stripe__green {
      background-color: $personas--green;
    }
    .rainbow-stripe__maroon {
      background-color: $personas--maroon;
    }
    .rainbow-stripe__yellow {
      background-color: $personas--yellow;
    }
    .rainbow-stripe__grey {
      background-color: $personas--grey;
    }
  }

  .get-started-with-product-cta__grid {
    display: grid;
    gap: 97px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding-top: 58px;
    padding-bottom: 52px;
    max-width: 1300px;
    margin: auto;
    
    @media (max-width: $md-max) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      gap: 24px;
      padding-top: 38px;
    }
  }

  .get-started-with-product-cta__content {
    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 150%;
      text-align: left;
      margin-bottom: 8px;
      @media (max-width: $md-max) {
        font-size: 18px;
      }
    }

    h1 {
      text-align: left;
      text-transform: uppercase;
      font-size: 48px;
      line-height: 150%;
      font-weight: 700;
      margin-bottom: 8px;
      @media (max-width: $md-max) {
        font-size: 32px;
      }
    }

    p {
      text-align: left;
      margin-bottom: 8px;
    }
  }
}

.get-started-with-product-cta__alt-version {
  margin-top: -170px;
  
  @media (max-width: $md-max) {
    margin-top: -106px;
  }

  .get-started-with-product-cta__rainbow-stripe {
    display: none;
  }
}