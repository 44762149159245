.community-summaries__grid {
  margin: 104px auto;
  max-width: 1300px;
  display: grid;
  gap: 27px;
  justify-content: center;
  grid-template-rows: max-content;

  h2 {
    text-align: left;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 150%;
    margin-bottom: 20px;
    @media (max-width: $sm-max) {
      text-align: center;
      margin-bottom: 16px;
      font-size: 24px;
    }
  }

  @media (max-width: $md-max) {
    grid-template-rows: 1fr repeat(3, max-content);
  }

  @media (max-width: $sm-max) {
    grid-template-rows: 1fr repeat(6, max-content);
  }

  @media (min-width: $sm-min) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $md-min) {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  @media (min-width: $lg-min) {
    grid-auto-rows: 1fr;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  @media (min-width: $xl-min) {
    grid-auto-rows: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
  }
}

.community-summaries__main {
  line-height: 1.5;
  padding: 10px 0;
  grid-row: 1 / 1;
  grid-column: 1 / -1;

  @media (min-width: $xl-min) {
    grid-row: 1 / end;
    grid-column: 1 / 1;
  }

  p {
    margin-bottom: 34px;
  }

  .community-summaries__button-grid {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    @media (max-width: $xl-max) {
      width: max-content;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
    }
    @media (max-width: $sm-max) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      grid-template-columns: 1fr;
      gap: unset;
    }
  }
  
  .button {
    display: block;
    text-align: center;
    margin-bottom: 16px;
    font-size: 16px;
    padding: 8px 33px;

    &:hover {
      color: #fff;
    }

    &:nth-child(2n) {
      background-color: transparent;
      border: 2px $personas solid;
      color: $personas !important;
      padding: 6px 32px;
      &:hover {
        border: 2px $personas--dark solid;
        color: $personas--dark !important;
      }
    }
    
    @media (max-width: $xl-max) {
      width: 100%;
    }
  }
  @media (max-width: $sm-max) {
    h2,
    p,
    a {
      text-align: center;
      margin-left: auto;
     margin-right: auto;
    }
  }
}

.community-tiles-flex {
  display: flex;
  flex-flow: row wrap;

  @media (min-width: $sm-min) {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }

  > div {
    flex: 0 1 100%;

    @media (min-width: $sm-min) {
      margin: 0 7.5px 15px;
      flex: 0 1 50%;
      flex-basis: calc(50% - 15px);
    }

    @media (min-width: $md-min) {
      flex: 0 1 33.333%;
      flex-basis: calc(33.333% - 15px);
    }

    @media (min-width: $lg-min) {
      flex: 0 1 25%;
      flex-basis: calc(25% - 15px);
    }

    @media (min-width: $xl-min) {
      flex: 0 0 1/6 * 100%;
      flex-basis: calc(1 / 6 * 100% - 15px);
      margin-bottom: 0;
    }
  }
}
