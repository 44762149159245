.resource-tile {
  .callout__content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .body--lead,
    blockquote {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 20px;
      text-transform: none;
      letter-spacing: 0;
      text-align: left;
      line-height: 1.6;

      strong {
        font-weight: normal;
      }
    }

    strong {
      font-weight: normal;
    }

    blockquote {
      background: none;
      padding: 0;
      border: none;
      color: $black;
      font-style: normal;

      &::before {
        content: none;
      }
    }
  }
}
