.visit-us {
  .row + .row {
    margin-top: 30px !important;

    @media (min-width: $md-min) {
      margin-top: 120px !important;
    }
  }
}

.visit-us__location-heading {
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: $sm-min) {
    margin-bottom: 30px;
  }
}

.visit-us__icon {
  display: block;
  margin: 0 auto;
  width: 30px;
  position: relative;
  top: 5px;
  text-align: center;

  svg {
    height: 30px;
  }
}

.visit-us__icon-text {
  word-wrap: break-word;
  margin-bottom: 30px;
}

.visit-us__spacer {
  margin-top: 60px;
}
.visit-us__callout {
  padding: 20px 10px 30px;
}
