.basicLightbox {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0.01;
  -webkit-transition: opacity 0.4s cubic-bezier(0.51, 0.92, 0.24, 1);
  transition: opacity 0.4s cubic-bezier(0.51, 0.92, 0.24, 1);
  z-index: 1000;
  will-change: opacity;
}

.basicLightbox--visible {
  opacity: 1;
}

.basicLightbox__placeholder {
  max-width: 100%;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.51, 0.92, 0.24, 1);
  transition: transform 0.4s cubic-bezier(0.51, 0.92, 0.24, 1);
  transition: transform 0.4s cubic-bezier(0.51, 0.92, 0.24, 1),
    -webkit-transform 0.4s cubic-bezier(0.51, 0.92, 0.24, 1);
  z-index: 1;
  will-change: transform;
}

.basicLightbox__placeholder > img:first-child:last-child {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 95%;
  max-height: 95%;
  width: auto;
  height: auto;
}

.basicLightbox__placeholder > iframe:first-child:last-child {
  display: block;
  margin: 0 auto;
  max-width: 95%;
}

.basicLightbox__placeholder > [data-basiclightbox] {
  display: block !important;
}

.basicLightbox--img .basicLightbox__placeholder {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.basicLightbox--visible .basicLightbox__placeholder {
  -webkit-transform: scale(1);
  transform: scale(1);
}

[data-basiclightbox] {
  display: none !important;
}
