/* stylelint-disable no-descending-specificity */
.welcome {
  margin: auto;
  position: relative;
  margin-bottom: 70px;

  @media (max-width: $lg-max) {
    margin-bottom: 200px;
  }
  @media (max-width: $md-max) {
    margin-bottom: 40px;
  }

  // temp move later
  h1 {
    text-align: left;
    letter-spacing: 0;
    line-height: 1.5;
    font-weight: 700;
    text-transform: uppercase;
  }

  @media (max-width: $md-max) {
    grid-template-rows: auto auto;
  }
  .welcome__banner {
    display: grid;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    grid-template-columns: repeat(8, minmax(94px, 180px));
    grid-auto-rows: auto;
    justify-content: center;
    grid-template-areas:
      '. blank-1 one one . two two .'
      '. . . three three . four four'
      '. . . . five five . blank-2'
      '. . . . . . six six';

    @media (max-width: $lg-max) {
      grid-template-rows: repeat(4, 1fr);
      grid-template-areas:
        '. blank-1 one one . two two .'
        '. . . three three . four four'
        '. . . . five five . blank-2'
        '. . . . . . six six';
    }

    @media (max-width: $md-max) {
      grid-template-columns: repeat(6, minmax(94px, 180px));
      grid-template-rows: repeat(2, 1fr);
      grid-template-areas:
        'one one two two three three'
        'four four five five six six';
    }

    // Larger mobile breakpoint
    @media (max-width: 568px) {
      grid-template-columns: repeat(4, minmax(94px, 180px));
      grid-template-rows: repeat(3, 1fr);
      grid-template-areas:
        'one one two two'
        'three three four four'
        'five five six six';
    }
  }

  .intro {
    margin: 80px 0;

    @media (max-width: $lg-max) and (min-width: $md-min) {
      margin: 120px 0 80px;
    }
  }

  .banner__persona {
    position: relative;
    color: $white;
    font-size: 12px;
  }

  .banner__persona::before {
    content: '';
    display: block;
    padding-bottom: 50%;
  }

  .banner__persona--blank {
    background-color: $white;

    @media (max-width: $sm-min) {
      display: none;
    }
  }

  .persona__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .content__title {
    font-weight: 700;
    display: grid;
    padding: 8px 12px;
  }

  .title__text {
    text-align: right;
    align-self: end;

    > span {
      @media (max-width: $sm-min) {
        display: none;
      }
    }
  }

  .content__img-container {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .img-container__img {
    height: 100%;
    object-fit: cover;
    max-width: 100%;
  }

  .banner__persona--100 {
    grid-area: one;
  }

  .banner__persona--200 {
    grid-area: two;
  }

  .banner__persona--300 {
    grid-area: three;
  }

  .banner__persona--400 {
    grid-area: four;
  }

  .banner__persona--500 {
    grid-area: five;
  }

  .banner__persona--600 {
    grid-area: six;
  }

  .banner__persona--700 {
    grid-area: six;
  }

  .blank--1 {
    grid-area: blank-1;
  }

  .blank--2 {
    grid-area: blank-2;
  }

  .welcome {
    position: relative;
  }

  .banner__content-wrapper {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    max-width: 1170px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(8, minmax(94px, 180px));
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas:
      '. . . . . . . .'
      '. . . . . . . .'
      'content content content content . . . .'
      'content content content content . . . .';

    @media (max-width: $lg-max) {
      max-width: 933px;
      grid-template-rows: repeat(4, 1fr);
      grid-template-areas:
        '. . . . . . . .'
        '. . . . . . . .'
        'content content content content . . . .'
        'content content content content . . . .';
      .banner__top-text {
        display: none;
      }
    }

    @media (max-width: $md-max) {
      grid-row: 2;
      grid-column: 1;
      display: initial;
      position: initial;
    }
  }

  .banner__top-text {
    grid-row: 2;
    grid-column: 1/4;
    align-self: end;
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 50%;
  }

  .banner__content {
    grid-area: content;
    margin-top: 18px;
    grid-row: 3/5;
    grid-column: 1/5;
    align-self: start;

    .banner__part-one {
      display: none;
      @media (max-width: $lg-max) {
        display: inline;
      }
    }
    p {
      padding-right: 62px;
    }
  }

  .content__header {
    margin-bottom: 8px;
    font-size: 42px;

    @media (max-width: $md-max) {
      font-size: 32px;
    }
  }
  .button {
    font-size: 18px;
    padding: 8px 33px;
    margin-top: 20px;
    width: fit-content;
    display: block;
    line-height: 150%;

    &:hover {
      color: $white;
    }
  }
}
