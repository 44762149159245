.content-filter {
  margin-bottom: 30px;
  position: relative;

  @media (min-width: $md-min) {
    margin-bottom: 30px;
  }

  > h4 {
    margin-bottom: 10px;

    @media (min-width: $sm-min) {
      margin-bottom: 0;
    }
  }
}

.content-filter__items {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-left: -30px;
  margin-right: -30px;
}

.content-filter__items--blue {
  .content-filter__item {
    color: $blue;

    @media (min-width: $md-min) {
      background-image: url(../../images/icons/media-partners.svg);
    }

    &:hover,
    &:active,
    &:focus {
      color: $blue;
    }
  }
}

.content-filter__item {
  @include link-colour;
  @include hover-enlarge;

  flex: 0 0 auto;
  margin: 5px 10px;
  padding: 10px 15px;
  font-weight: 600;
  height: 50px;
  background-color: transparent;
  border-radius: $border-radius-sm;
  cursor: pointer;
  transition: transform $transition-time, color $transition-time,
    background-color $transition-time;

  @media (min-width: $md-min) {
    // flex: 0 0 200px;
    margin: 10px;
    padding-left: 60px;
    padding-right: 20px;
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: 30px;
    height: 55px;
  }

  @media (max-width: $md-max) {
    background-image: none !important;
  }

  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0.6);
  }

  &.content-filter__item--active {
    transform: scale(1);
    background-color: $white;
  }
}

.content-filter__item--articles {
  background-image: url(../../images/icons/resources/article.png);
}

.content-filter__item--press-releases {
  background-image: url(../../images/icons/resources/press-release.svg);
}

.content-filter__item--reports {
  background-image: url(../../images/icons/resources/report.svg);
}

.content-filter__item--success-stories {
  background-image: url(../../images/icons/resources/success-story.svg);
}

.content-filter__item--videos {
  background-image: url(../../images/icons/resources/video.svg);
}

.content-filter--no-bottom-margin {
  margin-bottom: 0;

  @media (min-width: $sm-min) {
    margin-bottom: 0;
  }
}

.content-filter__clear {
  @include close-label;

  @media (min-width: $sm-min) {
    top: 5px;
  }
}

.content-filter-grid {
  transition: opacity $transition-time / 2;
  opacity: 1;
}

.content-filter-grid--is-fetching {
  transition: opacity $transition-time / 2;
  opacity: 0.4;
}
