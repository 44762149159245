.image-content__wrapper {
  background-color: $white;
  margin: 104px 0;
  .image-content__container {
    max-width: $content-width;
    margin: auto;
    padding-bottom: 140px;
    padding-top: 148px;
    @media (max-width: $md-max) {
      padding-bottom: 29px;
      padding-top: 57px;
    }
  }
  .image-content__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    @media (max-width: $md-max) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
    }

    .image-content__content {
      padding-right: 63px;
      text-align: left;
      @media (max-width: $md-max) {
        padding: 0 22px;
        text-align: center;
      }
    }

    .image-content__content-block {
      margin-bottom: 32px;
      &:first-of-type {
        margin-top: 40px;
        @media (max-width: $md-max) {
          margin-top: 0;
        }
      }
    }

    .image-content__heading {
      text-align: left;
      text-transform: uppercase;
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 12px;
      @media (max-width: $md-max) {
        text-align: center;
        font-size: 24px;
      }
    }
    .image-content__sub-heading {
      text-align: left;
      color: $grey-300;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 8px;

      @media (max-width: $md-max) {
        text-align: center;
        font-size: 18px;
      }
    }

    .image-content__text {
      @media (max-width: $md-max) {
        text-align: center;
      }
      a {
        font-weight: bold;
        color: $personas;
        text-decoration: none;
        &:hover {
          color: $personas--dark;
        }
      }
    }

    .image-content__image {
      max-width: 100%;
      object-fit: cover;
      width: 100%;
      height: 100%;
      @media (max-width: $md-max) {
        padding: 0 22px;
      }
    }
  }
}
