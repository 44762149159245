.partners__partner-button {
  @include hover-enlarge;

  margin: 0;
  background-color: rgba(255, 255, 255, 0.7) !important;
  border-radius: $border-radius-md;
  transition: transform $transition-time, background-color $transition-time;
  cursor: pointer;

  @media (min-width: $sm-min) {
    margin: 40px 20px;
    max-width: 300px;

    img.ui.image {
      max-height: 150px;
      margin: 0 auto;
      display: inline-block;
    }
  }

  @media (max-width: $sm-max) {
    padding: 0 !important;
  }

  &:hover,
  &:focus,
  &:active {
    // background-color: rgba(255, 255, 255, 1) !important;
    border-radius: 5px;
    box-shadow: none;
  }
}

.partner__logo {
  max-width: 350px !important;
  max-height: 200px !important;
  display: block;
  margin: 0 auto;
}

.partners__partner-img {
  max-height: 120px;
}

.partner__channels {
  @media (min-width: $sm-min) {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  > div {
    margin: 10px;
    text-align: center;
    min-width: 210px;
  }
}

.partner__channel {
  color: $cyan;
  margin-top: 10px;
}

.partner__description {
  margin: 20px 0;
}

.partner__buttons {
  margin-top: 20px;
  text-align: center;

  @media (min-width: $sm-min) {
    margin-top: 30px;
    margin-bottom: -20px;
  }

  .button {
    margin: 0 15px 20px;
  }
}
.partners-filter__media-title {
  margin-top: 30px;
}
