.community-carousel {
  background-color: $white;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 500px;
  padding: 80px 0 50px;
  position: relative;

  @media (max-width: $md-max) {
    height: fit-content;
    padding: 80px 0 60px;
  }
  @media (max-width: $sm-max) {
    padding: 50px 0 60px;
  }
}

.community-carousel__wrapper {
  display: flex;
  width: 1360px;
  margin: auto;
  padding-left: 30px;

  .community-summary-tile__tile {
    img {
      position: absolute;
      top: 0;
    }
  }

  @media (max-width: $sm-max) {
    flex-direction: column;
    gap: 24px;
  }
}

.community-carousel__head-mobile {
  padding: 0 30px;
  margin-bottom: 30px;
  display: none;
  h2 {
    text-align: left;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
  }
  p {
    text-align: left;
    margin-top: 20px;
  }

  @media (max-width: $md-max) {
    display: block;
  }
}

.community-carousel__head {
  display: block;

  @media (max-width: $md-max) {
    display: none;
  }

  padding: 30px 30px 0 0;
  min-width: 300px;

  h2 {
    text-align: left;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
  }
  p {
    text-align: left;
    margin-top: 20px;
  }
}

.community-carousel__items-wrapper {
  width: 100%;
  height: 307px;
}

.community-carousel__item {
  display: grid;
  gap: 24px;
  grid-auto-columns: 307px;
}

.slider-custom {
  height: 100%;

  .community-summary-tile {
    margin: 0 24px 0 0;
  }

  .slick-track,
  .slick-list {
    height: 100%;
  }

  .slick-dots {
    position: relative;
    bottom: -55px;
    left: -300px;
    width: 100vw;

    @media (max-width: $md-max) {
      left: -20px;
      bottom: -14px;
    }
  }
}

.community-carousel-full-width {
  background-color: transparent;
  left: auto;
  transform: translateX(0%);
  width: auto;
  height: 420px;
  padding: 0;
  position: relative;

  .community-carousel__wrapper {
    padding-left: 0;
  }
  .community-carousel__head {
    display: none;
  }

  .slick-slider {
    position: unset;
  }

  .slick-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 65px;
    width: fit-content;
    margin: auto;
    @media (max-width: $sm-max) {
      bottom: 30px;
    }
  }
  .community-carousel__items-wrapper {
    width: 100vw;
  }
}
