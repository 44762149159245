.share-button {
  @include hover-enlarge;

  background-image: url('../../images/icons/share.png');
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  height: 30px;
  line-height: 30px;
  padding-left: 45px;
  color: $deep-cyan;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.share-button-container--inline {
  .share-button {
    height: 25px;
    line-height: 25px;
    padding-left: 40px;
  }
}

.share-button--large {
  @include body--lead;
}

.share-message {
  @media (min-width: $sm-min) {
    display: flex;
    flex-flow: row nowrap;
    align-items: top;
    justify-content: center;
  }
}

.share-message__first-col {
  @media (min-width: $md-min) {
    background-image: url('../../images/icons/share-tip.svg');
    background-position: right 20px;
    background-repeat: no-repeat;
    flex: 0 0 100px;
  }
}

.share-message__user-icon {
  @media (min-width: $md-min) {
    width: 60px;
    height: 60px;
    background-image: url('../../images/icons/user.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    border: 2px solid $mischka;
    border-radius: 50%;
  }
}

.share-message__text {
  flex: 1 0 auto;
  background-color: $white;
  padding: 20px 30px;
  position: relative;
  max-width: 600px;
  width: 100%;

  .share-message__title {
    @media (min-width: $sm-min) {
      width: calc(100% - 100px);
    }
  }

  a {
    margin-top: 10px;
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: $sm-min) {
      width: calc(100% - 100px);
    }
  }
}

.share-message__copy {
  margin: 20px 0 0 !important;

  @media (min-width: $sm-min) {
    position: absolute;
    right: 25px;
    top: 25px;
    margin: 0 !important;
  }
}

.share__socials {
  display: flex;
  flex-flow: row wrap;
  margin: 20px -20px 0;

  @media (min-width: $md-min) {
    margin: 60px 0 0;
  }

  > a {
    @include body--lead;
    @include hover-enlarge;

    flex: 1 0 auto;
    margin: 10px 20px;
    height: 40px;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;

    .icon {
      color: $cyan;
      text-decoration: none;
      margin-right: 15px;
    }
  }
}
