.info-tab__wrapper {
  width: 100%;
  margin: 104px 0;
  .info-tab__title {
    text-align: left;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 150%;
    margin-bottom: 34px;
    @media (max-width: $sm-max) {
      text-align: center;
      margin-bottom: 16px;
      font-size: 24px;
    }
  }

  .info-tab__intro-text {
    margin-bottom: 53px;
    @media (max-width: $sm-max) {
      text-align: center;
    }
  }

  .info-tab__container {
    transition: height 0.2s ease-in-out 0.3s;
    background-color: $white;
    max-width: $content-width;
    width: 100%;
    margin: auto;
    overflow: hidden;
    @media (max-width: $md-max) {
      transition: height 0.3s ease-in-out 0s;
    }
  }

  .info-tab {
    .info-tab__switch-wrapper {
      position: relative;
      overflow: hidden;
      transition: height 0.3s ease-in-out;
      .info-tab__toggle {
        transition: transform 0.6s;
        position: absolute;
        z-index: 3;
        right: 24px;
        top: 15px;
        visibility: hidden;
        @media (max-width: $md-max) {
          visibility: visible;
        }
        &.open {
          transform-origin: center;
          transform: rotateX(180deg) translateY(2px);
          transform-style: preserve-3d;
        }
      }
    }
    .info-tab__switches {
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 3px;
      background-color: $grey-100;
      @media (max-width: $md-max) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }

      .info-tab__switch {
        grid-row: 1;
        cursor: pointer;
        transition: background-color 0.2s ease-in, color 0.2s ease-in;
        text-align: center;
        font-size: 18px;
        line-height: 150%;
        padding: 13px 0;
        background-color: $grey-200;
        color: $grey-300;
        font-weight: 700;
        box-sizing: border-box;
        .info-tab__toggle {
          visibility: hidden;
        }
        @media (max-width: $md-max) {
          grid-row: auto;
          border-bottom: 4px solid $grey-100;
        }
        &:hover,
        &.currentTab {
          background-color: $white;
          color: $personas;
          z-index: 2;
          @media (max-width: $md-max) {
            .info-tab__toggle {
              visibility: visible;
            }
          }
        }
        &.currentTab {
          @media (max-width: $md-max) {
            grid-row: 1;
          }
        }
      }
    }

    .info-tab__content-grid-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      // may remove
      @media (max-width: $md-max) {
        padding-bottom: 14px;
        padding-top: 14px;
      }
      .info-tab__content-grid {
        transition: opacity 0.2s ease-in 0s;
        grid-row: 1;
        grid-column: 1;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
        opacity: 0;
        pointer-events: none;
        display: grid;
        height: 0;
        @media (max-width: $md-max) {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
        }

        &.current {
          transition: opacity 0.2s ease-in 0.4s;
          pointer-events: all;
          opacity: 1;
          height: fit-content;
        }

        .info-tab__content-block {
          padding: 60px 24px 40px 24px;
          text-align: center;

          @media (max-width: $md-max) {
            padding: 33px 14px;
          }
          .content-block__heading {
            font-weight: 700;
            font-size: 24px;
            line-height: 150%;
            margin-bottom: 8px;
            margin-top: 15px;
            @media (max-width: $md-max) {
              font-size: 18px;
            }
          }

          .content-block__text {
            font-size: 16px;
            line-height: 170%;
          }
          .info-tab__content-block--img {
            height: 100px;
            width: 100px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              object-position: center;
              width: inherit;
              height: fit-content;
              object-fit: cover;
            }
            svg {
              fill: $white !important;
            }
          }
        }
      }
    }
  }
}
