.partners {
  min-height: 200px;

  @media (max-width: $sm-min) {
    min-height: 400px;
  }

  .column {
    text-align: center !important;
  }
}

.callout__item--unaddressed-mail {
  @media (min-width: $md-min) {
    background-position: 10px center;
  }
}
