.community-summary-tile__stats-container {
  display: flex;
  margin: 7px 0;
}

.community-summary-tile__stat {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  line-height: 1;
  width: 100%;
  flex-wrap: wrap;
}

.community-summary-tile__icon {
  flex: 0 0 22px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 100%;
    height: 100%;

    * {
      fill: inherit !important;
      stroke: inherit !important;
    }
  }
}

.community-summary-tile__figure {
  flex: 0 0 auto;
  padding-left: 10px;
  color: $pale-sky;
  font-weight: 800;
  font-size: 12px;
}
