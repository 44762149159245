.icon-steps__icon {
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;

  @media (min-width: $sm-min) {
    height: 150px;
    margin-bottom: 30px;
  }
}

.icon-steps__text {
  @media (min-width: $sm-min) {
    text-align: center;
  }
}

.icon-steps__circle-container {
  position: relative;
  width: 135px;
  height: 135px;
  margin: 0 auto 20px;
}

.icon-steps__circle {
  transform: rotate(-90deg) scale(1, -1);
  position: absolute;
  z-index: 1;
  width: 100%;
  height: auto;
}

.icon-steps__circle-track {
  stroke: $athens-grey;
  stroke-width: 4;
  fill: none;
}

.icon-steps__circle-progress {
  stroke: $cyan;
  stroke-width: 4;
  fill: none;
}
