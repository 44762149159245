.persona-tile {
  background-color: $athens-grey;
  padding: 15px;
  position: relative;
}

.persona-tile__content {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  > div {
    flex: 0 1 auto;

    &.persona-tile__text {
      flex: 1 1 auto;
    }

    &.persona-tile__button-container {
      padding-top: 25px;
      flex: 0 0 auto;
    }
  }
}

.persona-tile__name {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin: 5px 0;
}

.persona-tile__number {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
}

.persona-tile__stats-container {
  margin: 8px 0;
}

.persona-tile-stat {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  line-height: 1;
  width: 100%;
  flex-wrap: wrap;

  svg{
    width: 20px;
    height: 25px;
  }
}

.persona-tile-stat__icon {
  flex: 0 0 22px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;

  .isvg {
    svg {
      width: 22px;
      height: 22px;

      * {
        fill: inherit !important;
        stroke: inherit !important;
      }
    }
  }
}

.persona-tile-stat__figure {
  flex: 0 0 auto;
  padding-left: 10px;
  color: $pale-sky;
  font-weight: 800;
  font-size: 12px;
}

.persona-tile__text {
  font-size: 14px;
  line-height: 1.3;
}

.persona-tiles-flex {
  display: flex;
  flex-flow: row wrap;

  @media (min-width: $sm-min) {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }

  > div {
    flex: 0 1 100%;
    margin-bottom: 15px;

    @media (min-width: $sm-min) {
      margin: 0 7.5px 15px;
      flex: 0 1 50%;
      flex-basis: calc(50% - 15px);
    }

    @media (min-width: $md-min) {
      flex: 0 1 33.333%;
      flex-basis: calc(33.333% - 15px);
    }

    @media (min-width: 1300px) {
      flex: 0 1 25%;
      flex-basis: calc(25% - 15px);
    }
  }
}
