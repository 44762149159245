.read-more__link {
  font-size: 16px;
  font-weight: 700;
  color: inherit;
  margin-top: auto;
  margin-top: 16px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none !important;

    svg path {
      animation: widen-arrow 0.2s linear forwards;
    }
  }
}

.read-more__svg {
  margin-left: 8px;
  display: inline-block;
  width: 30px;

  path {
    animation: shorten-arrow 0.2s linear forwards;
  }
}
