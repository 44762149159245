.contact-options {
  background-image: url(../../images/backgrounds/people-photo.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  h3 {
    margin: 0 0 10px;

    @media (min-width: $sm-min) {
      font-size: 22px;
      margin: 10px 0 20px;
    }
  }
  .callout {
    padding-left: 0;
    padding-right: 0;
  }
  .contact-options__link {
    color: $deep-cyan;
    font-weight: 600;

    @media (min-width: $sm-min) {
      margin-bottom: 10px;
    }
  }
}
.contact-options__intl-numbers {
  text-align: center;
  margin-top: 5px;
}
