.other-products {
  h2 {
    margin-bottom: 30px;
  }
}
.other-products__product {
  display: flex;
  flex-flow: row nowrap;
}
.other-products__product-icon {
  flex: 0 0 75px;
}
.other-products__product-text {
  flex: 1 0 auto;
  margin-left: 30px;
  margin-right: 20px;
  flex-basis: calc(100% - 120px);

  @media (max-width: $sm-min) {
    margin: 0;
  }

  h4 {
    text-align: left;
    line-height: 24px;
    margin-bottom: 5px;

    a {
      color: $cyan;
      text-decoration: none;
    }
  }
}
