.page-title,
%page-title {
  min-height: 140px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-image: url(../../images/backgrounds/multifaceted_bg_cyan.svg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 20px;
  color: $white;

  @media (min-width: $sm-min) {
    min-height: 200px;
    height: 200px; /* IE11 bugfix - flex children won't vertically centre unless flex container has a height property */
  }

  h1 {
    @include h1-page-title;
  }
}

.page-title--resource-single {
  @media (min-width: $sm-min) {
    min-height: 220px;
  }
}

.page-title__strapline {
  margin: 0;
  font-size: 20px;
  line-height: (36 / 26);
  color: $white;
  text-align: center;
  letter-spacing: 0.8px;

  @media (max-width: $sm-max) {
    font-size: 18px;
  }
}

.page-title__icon {
  border: 2px solid $white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $sm-min) {
    width: 75px;
    height: 75px;
    margin-bottom: 10px;
  }

  svg {
    width: 60%;
    height: 60%;
  }
}

.page-title__icon--article {
  background-image: url(../../images/icons/resources/article-white.png);
}

.page-title__inner {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
}

.page-title__title {
  @include h1;
}
