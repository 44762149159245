.video-row__title {
  margin-bottom: 20px;

  @media (min-width: $sm-min) {
    margin-bottom: 40px;
  }
}

.video-placeholder {
  max-width: $ten-col-width;
  margin: 0 auto;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: 0.6;
  }
}

.video-placeholder__image {
  width: 100%;
  display: block;
}

.video-placeholder__play-button-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;

  &:hover,
  &:focus {
    .video-placeholder__play-button {
      transform: scale(1.07);
    }
  }

  &:active {
    transform: scale(0.98);
    transition-duration: 0.1s;
  }
}

.video-placeholder__play-button {
  transition: transform $transition-time;
  width: 60px;
  height: 60px;
  background-image: url('../../images/icons/play.svg');
  background-size: 65% 65%;
  background-position: 14px center;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 2px solid $mischka;
}

.video-row__caption {
  max-width: $ten-col-width;
  margin: 30px auto 0;

  @media (min-width: $sm-min) {
    margin: 40px auto 0;
  }
}
