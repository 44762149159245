.partners-filter {
  h4 {
    margin: 0;
  }
}
.partners-filter__item {
  display: flex;
  flex-flow: row nowrap;
  height: 45px;
  align-items: center;
  justify-content: center;
  color: $deep-cyan;
  font-weight: 600;
  background-repeat: no-repeat;
  background-position: 40px center;
  background-size: 25px;
  width: 100%;
  text-align: left;

  .field {
    margin-right: 65px;
    flex: 0 0 auto;
    position: relative;
    top: 3px;
  }
}
.partners-filter__name {
  flex: 1 0 auto;
}
.partners-filter__clear {
  @include close-label;

  background: none;
  top: 25px;

  @media (min-width: $sm-min) {
    padding-right: 0;
  }
}
.partners-filter__no-match {
  color: #747e8d;
  margin-bottom: 50px;

  a {
    color: inherit;
    cursor: pointer;
  }
}
