.persona-listing-header__wrapper {
    max-width: 1440px;
    margin: auto;
  
    .persona-listing-header__outer-container-grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
  
    .persona-listing-header__mosaic-grid {
      grid-row: 1;
      grid-column: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      height: fit-content;
  
      @media (max-width: $sm-max) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
      }
    }
  
    .container {
      grid-row: 1;
      grid-column: 1;
      @media (max-width: $sm-max) {
        grid-row: 2;
        grid-column: 1;
      }
    }
  
    .persona-listing-header__content-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      height: 100%;
      align-items: center;
      justify-content: center;
      @media (max-width: $sm-max) {
        justify-content: unset;
        grid-template-columns: auto;
      }
    }
  
    .persona-listing-header__content--container {
      padding-right: 100px;
      padding-top: 47px;
      h1 {
        text-align: left;
        font-weight: 700;
        font-size: 48px;
        line-height: 150%;
        margin-bottom: 24px;
        text-transform: uppercase;
      }
      p {
        text-align: left;
        margin-bottom: 40px;
      }
      @media (max-width: $md-max) {
        padding-right: 20px;
       
      }
      @media (max-width: $sm-max) {
        padding-right: 0;
        padding-top: 34px;
      }
  
      .persona-listing-header__button-group {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
        width: fit-content;
  
        .button {
          width: 100%;
          border: 3px solid $personas;
          text-align: center;
          padding: 5px 13px;
  
          &:focus,
          &:hover {
            color: $white;
            border: 3px solid $personas--dark;
          }
        }
        :nth-child(2n) {
          color: $personas !important;
          background-color: transparent;
          &:hover {
            color: $personas--dark !important;
          }
        }
      }
    }
  
    .persona-listing-header__mosaic {
      grid-row: 1;
      grid-column: 2;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      width: 100%;
      @media (max-width: $md-max) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
      }
      @media (max-width: $sm-max) {
        grid-column: 1;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }
    }
    .persona-listing-header__mosaic-block {
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      position: relative;
  
      &.solid-color {
        .overlay {
          opacity: 1;
        }
      }
  
      &.white-mosaic-block {
        background-color: $white;
      }
      &.green-mosaic-block {
        background-color: $personas--green;
      }
      &.blue-mosaic-block {
        background-color: $personas--dark-blue;
      }
      &.grey-mosaic-block {
        background-color: $personas--grey;
      }
  
      .mosaic-block-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  
      .overlay {
        transition: opacity 0.3s ease-out;
        position: absolute;
        width: 120%;
        height: 120%;
        opacity: 0.5;
  
        &.yellow {
          background-color: $personas--yellow;
        }
        &.light-blue {
          background-color: $personas--light-blue;
        }
        &.maroon {
          background-color: $personas--maroon;
        }
      }
    }
  }
  