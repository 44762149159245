.feature-grid {
  margin: 20px 0 0;

  @media (min-width: $sm-min) {
    margin: 40px 0 10px;
  }

  .grid.stackable > .column:not(.row) {
    margin-bottom: 20px !important;

    @media (min-width: $sm-min) {
      margin-bottom: 30px !important;
      text-align: center;
    }

    h4 {
      @media (min-width: $sm-min) {
        margin-bottom: 10px;
      }
    }
  }
}
