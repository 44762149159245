.inline-callback-form-row {
  padding: 35px 0 50px;
  background-color: $cyan;
  position: relative;
  width: 100%;
  height: 100%;
  // background-image: url(../images/backgrounds/people-photo.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  label {
    font-weight: 400 !important;
  }

  &:not(.updated) {
    background-image: url(../../images/backgrounds/people-photo.jpg);
  }
}

h3.inline-callback-form__title {
  color: $deep-cyan;

  @media (min-width: $md-min) {
    margin-top: 32px;
  }

  @media (max-width: $md-max) {
    margin-bottom: -10px;
  }
}

.ui.button.inline-callback-form__submit {
  @media (min-width: $sm-min) {
    margin-top: 27px;
  }

  @media (max-width: $sm-max) {
    display: block;
    margin: 0 auto;
  }
}

.inline-callback-form__form {
  .form__response--success {
    margin-top: 35px;
  }

  .inline-callback-form__form-group {
    display: flex;
    gap: 25px;

    @media (max-width: $sm-max) {
      flex-direction: column;
    }

    .inline-callback-form__form-item {
      width: 100%;

      select,
      input {
        transition: all 0.3s;
        padding-left: 12px;
        height: 42px;
        border: none !important;
        border-radius: 0 !important;
        color: $personas !important;
        font-weight: 700;
        .placeholder-option,
        &::placeholder {
          color: $grey-300;
          font-weight: 400;
        }
      }
    }
  }

  .ui.button.inline-callback-form__submit {
    border-radius: 0;
    transition: background-color 0.2s;
    &:hover {
      transform: scale(1) !important;
      background-color: $personas--dark;
    }
  }

  &.updated {
    .inline-callback-form__form-group {
      margin-bottom: 24px;

      .inline-callback-form__form-item {
        label {
          color: $grey-400;
        }

        select,
        input {
          background-color: $grey-100;
        }
      }
    }

    .ui.button.inline-callback-form__submit {
      width: 100%;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 1px;
    }
  }

  &:not(.updated) {
    .inline-callback-form__form-group {
      .inline-callback-form__form-item {
        input {
          border: 1px solid $deep-cyan !important;
          border-radius: 3px !important;
          color: $deep-cyan !important;
        }

        label {
          color: $deep-cyan;
          padding: 0 0 0 12px;
        }
      }
    }

    .ui.button.inline-callback-form__submit {
      border-radius: 4px;
    }

  }
}
