@mixin h1-page-title {
  font-size: 32px;
  color: $white;
  margin: 0;
  line-height: 1.25;
  letter-spacing: 1px;
  font-weight: 300;
  text-transform: none;

  @media (min-width: $sm-min) {
    font-size: 48px;
    letter-spacing: 1.5px;
  }
}
@mixin primary-transition {
  transition: all 0.3s ease-in-out;
}

/* Common styles for standard buttons */
@mixin default-button {
  @include primary-transition;

  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none !important;
  text-transform: uppercase;
  padding: 8px 16px;
}

@mixin button--primary {
  @include default-button;

  color: $white;
  background-color: $personas;

  &:hover {
    background-color: $personas--dark;
  }
}

@mixin button--transparent {
  @include default-button;

  color: $personas;
  background-color: $white;
  border: 2px solid $personas;

  &:hover {
    color: $personas--dark;
    border-color: $personas--dark;
  }
}

@mixin button--white {
  @include default-button;

  color: $personas;
  background-color: $white;

  &:hover {
    color: $personas--dark;
    background-color: $grey-100;
  }
}