.two-column {
  img {
    width: 500px;
    height: auto;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .row {
    padding-bottom: 0 !important;
    padding-top: 45px !important;
  }

  .body--lead {
    .video-wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
      width: 100%;
      max-width: 100%;
      display: block;
    }
  
    iframe {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 100% !important;
    }
  }
  
}
