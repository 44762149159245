.video-and-text__wrapper {
  margin: 104px auto;

  .video-and-text__title {
    text-align: left;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 150%;
    margin-bottom: 34px;
    @media (max-width: $sm-max) {
      margin-bottom: 32px;
      font-size: 24px;
    }
  }

  .video-and-text__grid {
    display: grid;
    grid-template-columns: 73% auto;
    gap: 20px;
    grid-template-rows: 1fr;
    @media (max-width: $lg-max) {
      grid-template-columns: 70% auto;
      grid-template-rows: auto;
    }
    @media (max-width: $md-max) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
    }
  }
  .video-and-text__video {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
  .video-and-text__content {
    background-color: $white;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    p {
      margin: 15px 0 32px 0;
    }
    a {
      color: $personas;
      font-weight: 700;
      font-size: 18px;
      line-height: 150%;
      margin-bottom: 8px;
      text-decoration: none;
      display: flex;
      align-items: center;
      &::before {
        content: url('../../images/icons/download-icon.svg');
        margin-right: 13px;
        transform: translateY(1px);
      }
      &:hover {
        color: $personas--dark;
        &::before {
          color: $personas--dark;
        }
      }
    }
  }
  .video-and-text__bottom-text--wrapper {
    .video-and-text__bottom-text--container {
      margin-top: 50px;
    }

    .video-and-text__bottom-text--heading {
      text-align: left;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      margin-bottom: 16px;
    }

    .video-and-text__button-wrapper {
      margin-top: 47px;
    }

    .video-and-text__button {
      transition: background-color 0.2s ease-in-out;
      background-color: $personas;
      padding: 8px 33px;
      text-transform: uppercase;
      font-weight: 700;
      color: $white;
      text-decoration: none;
      &:hover {
        background-color: $personas--dark;
      }
    }

    @media (max-width: $sm-max) {
      display: flex;
      flex-direction: column;
      align-items: center;
      p,
      .video-and-text__bottom-text--heading {
        text-align: center;
      }
      .video-and-text__bottom-text--heading {
        font-size: 18px;
      }
      .video-and-text__button-wrapper {
        margin-top: 20px;
      }
    }
  }
}
