.contact-form__intro {
  padding-bottom: 20px;
}

.contact-form__submit {
  flex: 0 0 auto;
  margin: 20px 0 !important;
}

.contact-form__form .ui.grid > .column:not(.row) {
  padding-top: 10px;
  padding-bottom: 10px;
}

.contact-form__success-message {
  padding: 1000px 0 50px;

  @media (min-width: $sm-min) {
    padding: 260px 0 50px;
  }

  @media (min-width: $md-min) {
    padding: 100px 0 50px;
  }
}

.contact-form__form .ui.dropdown,
.contact-form__form .ui.dropdown .item {
  font-size: 1em !important;
}
