.product-listing-page-content {
  margin-bottom: 40px;

  @media (min-width: $sm-min) {
    margin-bottom: 60px;
  }

  .ui.grid > .column:not(.row) {
    @media (min-width: $sm-min) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.product-col,
%product-col {
  background-color: $athens-grey;
  padding: 20px 20px 30px;
  position: relative;

  // @media (min-width: $sm-min) {
  //   padding: 40px 40px 60px;
  // }
}

.suite-col {
  @extend %product-col;

  @media (max-width: $container-width) {
    // margin-left: 20px;
    // margin-right: 20px;
  }
}

.product-col__title {
  //@include h2--tile;

  margin: 20px 0;
  color: $cyan;
  text-decoration: none !important;
}

.product-col__strapline {
  @include body--lead;

  margin-top: 20px;

  // @media (min-width: $sm-min) {
  text-align: center;
  // }
}

.product-col__suite-description {
  max-width: 870px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $sm-min) {
    margin-bottom: 40px;
  }
}

.product-col__grid {
  margin: 20px 0;

  @media (min-width: $sm-min) {
    // margin: 40px -15px;
    margin: 20px 10px;
  }
}

.product-col__grid-inner {
  background-color: $white;
  padding: 20px;
  position: relative;

  @media (min-width: $sm-min) {
    padding: 40px;
  }
}

.product-col__grid-title {
  text-decoration: none !important;

  h4 {
    color: $cyan;
    margin-bottom: 15px;
  }
}

.product-col__grid-text {
  margin-bottom: 60px;
}

.product-col__grid-button-container {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 20px;
  left: 0;

  .ui.button.inverted {
    border: 1px solid #d0d5dc;
    transition: all $transition-time;
    color: $cyan !important;
    &:hover,
    &:focus,
    &:active {
      border-color: transparent;
    }
  }

  @media (min-width: $sm-min) {
    bottom: 40px;
  }
}

.product-col__description {
  margin-bottom: 100px;
}

.product-col__product-button-container {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 40px;
  left: 0;
}
